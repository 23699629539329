<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-col cols="12" md="12">
      <base-material-card color="#cf9602" class="px-5 py-3">
        <template v-slot:heading>
          <div class="text-h3 font-weight-light">Order History</div>
        </template>
        <v-card-text>
          <v-data-table
            :headers="columns"
            :items="users"
            item-key="_id"
            hide-default-footer
            disable-pagination
            :loading="loading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item._id`]="{ index }">
              {{ numbers[index] }}
            </template>
            <template v-slot:[`item.store_name`]="{ item }">
              <div v-if="item.store_name[0] != null">
                {{ item.store_name[0].name }}
              </div>
            </template>
            <template v-slot:[`item.role_name`]="{ item }">
              <div v-if="item.role_name[0] != null">
                {{ item.role_name[0].name }}
              </div>
            </template>
            <template v-slot:[`item.user_type`]="{ item }">
              {{ item.user_type[0].name }}
            </template>
          </v-data-table>
          <div class="custom_pagination_design">
            <span>
              Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
              <b>{{ totalRecord }}</b> Records
            </span>
            <v-pagination
              class="float-right"
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
          </div>
        </v-card-text>
      </base-material-card>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: "CustomerOrderHistory",
  props: ["edit", "add", "customerId"],
  data() {
    return {
      moduleName: "Users",
      modulePermission: [],
      search: "",
      users: [],
      dialogDelete: false,
      deleteRecord: "",
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      loading: true,
      numbers: [],
      editFlash: false,
      addFlash: false,
      deleteFlash: false,
    };
  },
  computed: {
    columns() {
      return [
        { sortable: false, text: "Order Id", value: "id", width: "150px" },
        {
          text: "FirstName",
          value: "user_name[0].first_name",
          width: "150px",
        },
        {
          text: "LastName",
          value: "user_name[0].last_name",
          width: "150px",
        },
        {
          sortable: false,
          text: "Email",
          value: "user_name[0].email",
          width: "150px",
        },
        {
          sortable: false,
          text: "Order Status",
          value: "order_status",
          width: "150px",
        },
        {
          sortable: false,
          text: "Payment Status",
          value: "payment_status",
          width: "180px",
        },
        {
          sortable: false,
          text: "Order Date",
          value: "order_date",
          width: "150px",
        },
        {
          sortable: false,
          text: "Grand Total",
          value: "grant_total",
          width: "150px",
        },
        {
          sortable: false,
          text: "Delivery Charges",
          value: "delivery_charges",
          width: "180px",
        },
      ];
    },
  },
  methods: {
    getUsers() {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "ordersByStatus" +
            "?page=" +
            this.page,
          {
            driverId: null,
            customerId: this.customerId,
            orderStatus: ["Completed"],
          }
        )
        .then((response) => {
          if (response.status == 200) {
            this.users = response.data.orders.data;
            this.loading = false;
            this.totalPages = response.data.orders.last_page;
            this.startRecord = response.data.orders.from;
            this.endRecord = response.data.orders.to;
            this.totalRecord = response.data.orders.total;
            this.numbers = [];
            for (
              let num = response.data.orders.from;
              num <= response.data.orders.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getUsers();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + "user/delete/" + this.deleteRecord
        )
        .then((response) => {
          if (response.status == 200) {
            this.dialogDelete = false;
            this.deleteRecord = "";
            this.deleteFlash = true;
            this.getUsers();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteCategory(data) {
      this.dialogDelete = true;
      this.deleteRecord = data._id;
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getUsers();
    },
  },
  mounted() {
    this.getUsers();
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
    if (this.edit) {
      this.editFlash = true;
    }
    if (this.add) {
      this.addFlash = true;
    }
  },
};
</script>

<style>
</style>
